.dropdown {
  display: inline-block;
 
}

.dropdown-block {
  display: inline-flex;
}

.dropdown:hover:active{
  display: block !important;
}

div.dropdown:hover:active {
  display: block !important;
}

.options {
  align-self: center;
  color: #242057;
  font-size: 0.9rem;
  font-weight: 100;
  text-decoration: none;
  padding-left: 0.3rem;
  padding-right: 9rem;
  padding-top: 0.5rem;
  margin: 0;
  cursor: pointer;
}

.options-rtl {
  padding-left: 9rem;
  padding-right: 0.3rem;
}

.options-dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.dropdown:hover .options-dropdown {
  display: block;
}

.options-dropdown-item {
  color: #242057;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.options-dropdown-item:hover {
  background-color: #f1f1f1;
}
.icon {
  padding-top: 1rem;
}

.dropdown-block {
  display: inline-flex;
  min-width: 6rem
}


<<<<<<< HEAD
=======

>>>>>>> dev
/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}



