.snack-container {
  position: relative;
  background-color: #242057;
  color: #ffffff;
  padding: 0.43em;

}

.snack-content {
  padding-inline-start: 5em;
}

.snack-title {
  font-size: 1.1em;
  font-weight: 700;
  margin-inline-end: 0.3em;
}

.snack-container.rtl {
  direction: rtl;
}

.snack.icon.pop {
  bottom: 0;
  position: absolute;
  height: 3.5em;
}