body {

  color: #242057;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Hebrew', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline
}

.column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

span.blue {
  color: #009cff;
}

span.red {
  color: #ee3924;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.row.stretch {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.row.center {
  -webkit-justify-content: center;
          justify-content: center;
}

.row.spread {
  -webkit-justify-content: space-around;
          justify-content: space-around
}

.row.space>* {
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;
}


.omni-checkbox {
  -webkit-appearance: none;
  width: 1.3em;
  height: 1.3em !important;
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;
  background-color: #ffffff;
  border: 1px solid #d2d2d4;
  display: inline-block;
  position: relative;
  outline: none;
}

.omni-checkbox:checked {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  background-color: #009cff;
  color: #ffffff;
}

.omni-checkbox:checked:after {
  content: '\2713';
  font-size: 1.1em;
  font-weight: bold;
  position: absolute;
  bottom: -1px;
  left: 0px;
  color: #ffffff;
}

.omni-select {
  min-width: 200px;
}
.header {
  width: 100%;
  background-color: #f9f9f9;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 100;
}

.omni-logo {
  cursor: pointer;
}

.header-container {
  width: 100%;
  min-height: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.header-container .omni-logo img {
  width: 35vw;
}

.header-container .local03-logo img {
  width: 25vw;
}

.header-end {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  }

  .header-container {
    width: 90vw;

  }

}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  } */
}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  } */
}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}

.header-container.rtl {
  direction: rtl
}
.dropdown {
  display: inline-block;
 
}

.dropdown-block {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.dropdown:hover:active{
  display: block !important;
}

div.dropdown:hover:active {
  display: block !important;
}

.options {
  -webkit-align-self: center;
          align-self: center;
  color: #242057;
  font-size: 0.9rem;
  font-weight: 100;
  text-decoration: none;
  padding-left: 0.3rem;
  padding-right: 9rem;
  padding-top: 0.5rem;
  margin: 0;
  cursor: pointer;
}

.options-rtl {
  padding-left: 9rem;
  padding-right: 0.3rem;
}

.options-dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.dropdown:hover .options-dropdown {
  display: block;
}

.options-dropdown-item {
  color: #242057;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.options-dropdown-item:hover {
  background-color: #f1f1f1;
}
.icon {
  padding-top: 1rem;
}

.dropdown-block {
  display: -webkit-inline-flex;
  display: inline-flex;
  min-width: 6rem
}


<<<<<<< HEAD
=======

>>>>>>> dev
/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}




.footer {
  z-index: 5;
  bottom: 0;
  width: 100%;
  background-color: #dce0df;
  /* font-size: 0.8em; */
  font-size: 0.5em; 
  color: #242158;
}

.footer.rtl {
  direction: rtl;
}

.footer-credits {
  /* margin: 0 auto;
  padding: 2em; */
  font-size: 0.4em; /*was added*/
  font-weight: bold;
}

.footer-credits a {
  text-decoration: none;
  color: black;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}



.footer-credits img {
  width: 1.5em;
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
}

.underline {

  text-decoration: underline;
}

.footer-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* justify-content: center; */
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding: 12px;
  font-size: 0.8rem;
  /* min-height: 8vh; */
}

.footer-container span {
  padding: 2px;
}

.heading {
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 1.2em;

}

.footer-contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}



@media (min-width: 1281px) {

  .footer-contact-container {
    -webkit-flex-direction: row;
            flex-direction: row;
  }


}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
.main-content {
  width: 100%;

  -webkit-flex: 1 1;

          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  z-index: 10;
}



@media(min-width: 768px) {
  .main-content {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url(/static/media/background1.ee7589c1.png)
  }
}

@media (min-width:1240px) {



  .main-content {
    z-index: 1;
    /* padding-bottom: 200px; */
  }
}
.main-background-image {
  height: 8vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(/static/media/background1-mobile.31e6a5f3.png)
}

.bottom-background-image {
  height: 10vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(/static/media/background2-mobile.3d965fa9.png)
}



.footer-background {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.screen-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  background: -webkit-linear-gradient(#f0f0f0, #ffffff, #ffffff, #ffffff);
  background: linear-gradient(#f0f0f0, #ffffff, #ffffff, #ffffff)
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .main-background-image {
    display: none;
  }

  .bottom-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url(/static/media/background2.e08b9e31.png)
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .main-background-image {
    display: none;
  }

  .bottom-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url(/static/media/background2.e08b9e31.png)
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .main-background-image {
    display: none;
  }

  .bottom-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url(/static/media/background2.e08b9e31.png)
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-background-image {
    width: 60vw;
  }
}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .main-background-image {
    width: 70vw;
  }
}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}

.screen-title-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.screen-title-content {
  width: 95vw;
  padding: 0.8em;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: 1.3em;
  font-weight: bold;
}

.screen-title-content.rtl {
  direction: rtl;
}

.screen-title-image {

  right: 0;

}



@media (min-width: 1280px) {
  .screen-title-content {
    width: 60vw;
    font-size: 2em;
    padding: 0.8em;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .screen-title-content {
    width: 60vw;
    font-size: 2em;
    padding: 0.8em;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .screen-title-content {
    width: 95vw;
    font-size: 1.8em;
    padding: 0.6em;
  }


}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .card-image {
    width: 32vw;
    height: 15vw;
    padding: 16px;
  }

  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.5em;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
.package-card-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.package-card-content {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  background-color: #ffffff;

  -webkit-flex-direction: row;

          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.package-card-content.rtl {
  direction: rtl;
}

.package-card-info-container {
  display: -webkit-flex;
  display: flex;
  padding: 0.4em;
  -webkit-flex-direction: column;
          flex-direction: column;

  border: 2px solid #009cff;
  border-inline-end-width: 0;
  -webkit-flex: 3 1;
          flex: 3 1;
}



.package-card-title {
  color: #009cff;
  font-size: 1.3em;
  font-weight: 800;
}

.package-card-subtitle {
  color: #ee3823;
  font-size: 1.6em;
  font-weight: 800;
}

.package-card-extra-info {
  color: #242057;
  font-size: 0.8em;
  font-weight: 200;
}

.package-card-extra-info ul {
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAATElEQVQImW3NsQmAQBQE0XciVqmhqZlmNqAtmF09V4+pfPBQxImWHdhNpZQOO3okZMwtNkweIp8NhldZGUP8EiI2vxzxsdyndTJjvQD6MgrPrfYCgAAAAABJRU5ErkJggg==);
  list-style-position: inside;
  padding: 0;
}



.package-card-form-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-bottom: 0.8em;
  padding-top: 0.8em;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-size: 0.8em;
}

.package-card-form-container.rtl {
  direction: rtl;
}

.package-card-price-container {

  -webkit-flex: 1 1;

          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  font-size: 1.8em;
  font-weight: 800;
}

.package-card-price-container.red {

  background-color: #ee3823;
}


.package-card-price-container.blue {

  background-color: #009cff;
}

.package-card-price {}

.package-card-form-times {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-right: 1em;
  padding-left: 1em;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.package-card-form-input-row {
  /* font-size: 0.7em; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.5em;
}

.package-card-form-quantity-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  padding-bottom: 0.3em;
  /* font-size: 0.7em; */
}

.package-card-form-quantity input {
  text-align: center;
  border: none;
  width: 2em;
}

.package-card-form-input-row span {
  width: 25vw;
}

.package-card-form-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 30vw;
  height: 100%;
  border: none;
  padding: none;
  outline: none;
  margin: none;
}

.package-card-form-input-container {
  border: 1.2px solid #cececd;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.package-card-form-input-icon-container {
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 1.5em;
  height: 1.5em;
  padding: 2px;
  text-align: center;
}

.package-card-form-input-icon-container:focus {
  outline: none;
}

.package-card-arrow-container {}

.package-card-arrow {
  width: 1.2em;
}

.package-card-form-input-icon-container.start {
  background-color: #f6f6f4;
  border-inline-end-color: #cececd;
  border-inline-end-width: 1.2px;
  border-inline-end-style: solid;

}

.package-card-form-input-icon-container.end {
  background-color: #f6f6f4;
  border-inline-start-color: #cececd;
  border-inline-start-width: 1.2px;
  border-inline-start-style: solid;

}

.package-card-form-input-icon {
  font-size: 0.8em;
  color: #cececd;
  padding: 2px;
}




/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .package-card-form-times {
    -webkit-flex: 2 1;
            flex: 2 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .package-card-form-times span {
    width: auto;
    -webkit-margin-start: 0.8em;
            margin-inline-start: 0.8em;
    -webkit-margin-end: 0.8em;
            margin-inline-end: 0.8em;
    font-weight: bold;

  }

  .package-card-form-times input {
    width: auto;
  }

  .package-card-form-quantity {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-weight: bold;

  }

  .package-card-title {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-subtitle {
    font-size: 1.8em;
    font-weight: 800;
  }

  .package-card-info-container {
    padding: 0;

  }

  .package-card-info-titles {
    -webkit-flex: 1 1;
            flex: 1 1;


    border-inline-end-color: #009dfe;
    border-inline-end-width: 2px;
    border-inline-end-style: solid;

  }

  .package-card-extra-info {
    -webkit-padding-start: 2em;
            padding-inline-start: 2em;
    font-size: 0.85em;
    -webkit-flex: 2 1;
            flex: 2 1;
    font-weight: 200;
  }


  .package-card-info-container {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;

  }

  .package-card-info-titles {
    display: -webkit-flex;
    display: flex;
    line-height: 1.6em;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-padding-start: 1em;
            padding-inline-start: 1em;

  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .package-card-form-times {
    -webkit-flex: 2 1;
            flex: 2 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .package-card-form-times span {
    width: auto;
    -webkit-margin-start: 0.8em;
            margin-inline-start: 0.8em;
    -webkit-margin-end: 0.8em;
            margin-inline-end: 0.8em;
    font-weight: bold;

  }

  .package-card-form-times input {
    width: auto;
    -webkit-flex: 1 1;
            flex: 1 1;
  }

  .package-card-form-quantity {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-weight: bold;

  }



  .package-card-info-container {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;

  }

  .package-card-title {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-subtitle {
    font-size: 1.8em;
    font-weight: 800;
  }

  .package-card-info-container {
    padding: 0;

  }

  .package-card-info-titles {
    -webkit-flex: 1 1;
            flex: 1 1;


    border-inline-end-color: #009dfe;
    border-inline-end-width: 2px;
    border-inline-end-style: solid;

  }

  .package-card-extra-info {
    -webkit-padding-start: 2em;
            padding-inline-start: 2em;
    font-size: 0.85em;
    -webkit-flex: 2 1;
            flex: 2 1;
    font-weight: 200;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .package-card-form-times {
    -webkit-flex: 2 1;
            flex: 2 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .package-card-form-times span {
    width: auto;
    -webkit-margin-start: 0.8em;
            margin-inline-start: 0.8em;
    -webkit-margin-end: 0.8em;
            margin-inline-end: 0.8em;
    font-weight: bold;

  }

  .package-card-form-times input {
    width: auto;

  }

  .package-card-form-quantity {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-weight: bold;

  }



  .package-card-info-container {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;

  }

  .package-card-title {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-subtitle {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-info-container {
    padding: 0;

  }

  .package-card-info-titles {
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

    border-inline-end-color: #009dfe;
    border-inline-end-width: 2px;
    border-inline-end-style: solid;

    display: -webkit-flex;

    display: flex;
    line-height: 1.6em;

    -webkit-padding-start: 1em;

            padding-inline-start: 1em;

  }


  .package-card-extra-info {
    -webkit-padding-start: 2em;
            padding-inline-start: 2em;
    font-size: 0.85em;
    -webkit-flex: 2 1;
            flex: 2 1;
    font-weight: 200;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
.custom-footer{
    width: 100%;
    z-index: 1;
    text-align: center;
    bottom: 0;
}

.contactUs-footer{
    background-color: rgb(227, 35, 12);
    color: white;
}
.snack-container {
  position: relative;
  background-color: #242057;
  color: #ffffff;
  padding: 0.43em;

}

.snack-content {
  -webkit-padding-start: 5em;
          padding-inline-start: 5em;
}

.snack-title {
  font-size: 1.1em;
  font-weight: 700;
  -webkit-margin-end: 0.3em;
          margin-inline-end: 0.3em;
}

.snack-container.rtl {
  direction: rtl;
}

.snack.icon.pop {
  bottom: 0;
  position: absolute;
  height: 3.5em;
}
.tooltip-container {
  height: 5em;
  width: 8em;
  display: -webkit-flex;
  display: flex;
  padding: 1em;
  position: relative;
}

.tooltip-container.rtl {
  direction: rtl
}



.tooltip-content {
  font-size: 0.6em;
}

.tooltip-icon {
  width: 1em;
  height: 1em;
  position: absolute;
  top: 0.3em;
  right: -0.2em;
}

.tooltip-title {
  display: block;
  font-weight: bold;
  font-size: 12px;
}

.navigation-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.9em;
  font-weight: bold;
  border-top: 1px solid #9a9c96;
  padding-top: 10px;
}

.navigation-container.rtl {
  direction: rtl;
}


.disabled-link {
  pointer-events: none;
}

.navigation-container-active {
  color: #38af12;
}

.navigation-container-disabled {
  cursor: pointer;
  background: none;
  border: none;
  font: inherit;
  text-decoration: underline;
  color: #707070;
}

.navigation-container-disabled:focus {
  outline: 0;

}

.navigation-arrow {
  padding: 0.2em;
  font-size: 0.5em;
}
.omni-input {
  background-color: #ffffff;
  border: 1px solid #cececd;
  padding: 0.4em;
}

.omni-input-container {
  -webkit-flex: inherit;
          flex: inherit;

}

.omni-input-error {
  color: red;
  font-size: 0.8em;
}

.order-summary-item-table {

  display: inline;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
}

.order-summary-item-table th {

  text-align: left;

  text-align: initial;
}
.omni-button {
  background-color: #ee3924;
  color: #ffffff;
  border: none;
  width: 100%;
  padding: 0.8em;
  font-weight: 800;
  font-size: 1.2em;
}

.omni-button.success {
  background-color: #38af12;
}

.omni-button.disabled {
  background-color: #707070;
}
.order-form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}



@media(min-width:768px) {
  .order-form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .order-form-row>*:last-child {
    -webkit-margin-start: 1em;
            margin-inline-start: 1em;
  }



}

@media(min-width:1024px) {


  .order-form-row--responsive {
    -webkit-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    display: flex;
  }

  .order-form-row--responsive>*:first-child {
    -webkit-margin-end: 10px;
            margin-inline-end: 10px;
  }
}


.cvv-row-container {
  display: -webkit-flex;
  display: flex;
  position: relative;
}

#cvv-hover:hover .cvv-message-container {
  display: block;

}

.cvv-message-container {
  display: none;
  border-radius: 15px;
  box-shadow: 3px 3px 5px grey;
  top: -50px;
  position: absolute;
  width: 250px;
  height: 100px;
  border: 1px solid grey;
  background-color: white;
  z-index: 100;
  padding: 6px;
}



.cvv-image {

  -webkit-margin-end: 0.5em;

          margin-inline-end: 0.5em;

  width: 25px;
  height: 28px;
  -webkit-align-self: center;
          align-self: center;
}

.container-relative {
  position: relative;
}



.order-form-row input {
  margin-bottom: 1.3em;
}

.order-form-checkbox {
  -webkit-appearance: none;
  width: 1.3em;
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;
  height: 1.3em;
  background-color: #ffffff;
  border: 1px solid #d2d2d4;
  display: inline-block;
  position: relative;
}

.order-form-checkbox:checked {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  background-color: #009cff;
  color: #ffffff;
}

.order-form-checkbox:checked:after {
  content: '\2713';
  font-size: 1.1em;
  font-weight: bold;
  position: absolute;
  bottom: -1px;
  left: 0px;
  color: #ffffff;
}

.order-form-card-number {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.order-form-card-number label {
  -webkit-margin-after: 0.2em;
          margin-block-end: 0.2em;
}

.order-form-row-container {
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
}

.order-form-input-expiration {
  width: 10vw;
}

.order-form-terms {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  color: #51514f;
  font-size: 0.8em;
}

.order-form-errors {
  color: #ee3924;
  font-size: 0.8em;

}
.textfield-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.textfield-label {
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;

}



.textfield-input {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.input-error {
  border: 1px solid red;
}
.screen-card-title {
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 1em;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {




  .screen-card-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }




  .screen-card-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .screen-card-content>* {
    -webkit-margin-start: 1em;
            margin-inline-start: 1em;
    -webkit-margin-end: 1em;
            margin-inline-end: 1em;
  }

  .screen-card-content>*:first-child {
    -webkit-margin-start: 0;
            margin-inline-start: 0;
  }

  .screen-card-content>*:last-child {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }



}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {


  .screen-card-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .screen-card-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }


  .screen-card-content>* {
    -webkit-margin-start: 1em;
            margin-inline-start: 1em;
    -webkit-margin-end: 1em;
            margin-inline-end: 1em;
  }

  .screen-card-content>*:first-child {
    -webkit-margin-start: 0;
            margin-inline-start: 0;
  }

  .screen-card-content>*:last-child {
    -webkit-margin-end: 0;
            margin-inline-end: 0;
  }


}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .order-screen-container {
    width: 70vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
    -webkit-margin-before: 5vh;
            margin-block-start: 5vh;
  }

  .screen-card-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
.screen-card-section-strip {
  background-color: #009cff;
  color: #ffffff;
  padding: 0.2em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-size: 1em;
}


.screen-card-section {
  -webkit-flex: 1 1;
          flex: 1 1
}

.screen-card-section ul {
  list-style: none;
  padding: 0;
}

.screen-card-section li {
  line-height: 13px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAATElEQVQImW3NsQmAQBQE0XciVqmhqZlmNqAtmF09V4+pfPBQxImWHdhNpZQOO3okZMwtNkweIp8NhldZGUP8EiI2vxzxsdyndTJjvQD6MgrPrfYCgAAAAABJRU5ErkJggg==) no-repeat left 5px;
}

.screen-card-section ul.rtl li {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAATElEQVQImW3NsQmAQBQE0XciVqmhqZlmNqAtmF09V4+pfPBQxImWHdhNpZQOO3okZMwtNkweIp8NhldZGUP8EiI2vxzxsdyndTJjvQD6MgrPrfYCgAAAAABJRU5ErkJggg==) no-repeat right 5px;
}


@media (min-width: 1281px) {
  .screen-card-section-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 1em;
    font-weight: 900;
  }



}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {


  .screen-card-section-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 1em;
    font-weight: 900;
  }



}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}

.omni-radio {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 0.7em;
  height: 0.7em;
  border-radius: 0.35em;
  background-color: #ffffff;
  border: 1px solid #e1e0e5;
  -webkit-margin-end: 0.35em;
          margin-inline-end: 0.35em;
}

.omni-radio-selected {

  width: 0.4em;
  height: 0.4em;
  border-radius: 0.22em;
  background-color: #ee3725;
}


.omni-radio-group {
  margin-top: 0.2em;
  margin-bottom: 0.2em;

}

.omni-radio-group>* {
  margin-top: 0.5em;


}

.omni-radio-group input {
  -webkit-margin-end: 0.8em;
          margin-inline-end: 0.8em;

}

.omni-radio-option {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
}
.shipping-info-form {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.shipping-info-form>div.field {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.5em;
}

.shipping-country-select {
  -webkit-flex: 9.3 1;
          flex: 9.3 1;

}

.pickup-container {
  border: 1px solid #009cff;
  padding: 4px 8px;
  margin-bottom: 8px;
}
.page-container {
  margin: 0 auto;
  padding-top: 6vh;
  width: 95%;
}

.page-title {
  background-color: #242057;
  padding: 0.2em;
  font-weight: bold;
  color: #ffffff;
  font-size: 0.9em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;

}

.page-content {
  padding: 1em;
}

.page-container.rtl {
  direction: rtl;
}

@media(min-width: 768px) {
  .page-container {

    width: 60%;


  }
}
.admin-tab-bar-selected {
  background-color: #454083;
}

.admin-tab-bar {
  font-size: 0.85em;
  color: #ffffff;
  background-color: #242057;
  font-weight: bold;
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.admin-tab-bar a {
  padding: 0.6em;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
}
.omni-progress-container {
  direction: ltr;
  border: 1px solid #ed3823;
  padding: 1px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.omni-progress-fill {
  -webkit-animation-name: filling;
          animation-name: filling;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  border-radius: 10px;
  height: 12px;
  width: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  background: rgba(239, 244, 244, 1);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(239, 244, 244, 1)), color-stop(100%, rgba(248, 154, 147, 1))) !important;
  background: -webkit-linear-gradient(left, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  background: linear-gradient(to right, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eff4f4', endColorstr='#f89a93', GradientType=1) !important;
}

@-webkit-keyframes filling {
  from {
    width: 5%;
  }

  to {
    width: 99%;
  }
}

@keyframes filling {
  from {
    width: 5%;
  }

  to {
    width: 99%;
  }
}

.omni-progress-knob {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #ed3823;
}
.admin-rates-link {
  display: block;
  font-weight: bold;
  text-align: center;
}

.admin-rates-link div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 10vw;
  padding: 1em;

}

.admin-rates-link div>img {
  display: none;
  width: 2em;

}

.admin-rates-link-active {
  font-weight: normal;
  color: #ffffff;
}

.admin-rates-link-active div {
  background-color: #ee3823;
}

.admin-rates-link-active div>img {
  display: block;
}

.admin-rates-navbar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
}
.main-screen-container {
  width: 90vw;
  padding: 10px;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 2vh;
  text-align: center;
}

.main-screen-heading {
  font-weight: bold;
  color: #242057;
  font-size: 1.7em;
}

.main-screen-heading h2 {
  font-size: 1.3em;
  font-weight: 800;
  margin: 0;
}

.main-screen-heading h1 {
  font-size: 1.4em;
  font-weight: 500;
  margin: 0;
}

.card-charge-title {
  margin-top: 4px;
  display: block;
  color: #242057;
  font-size: 0.8em;
  font-weight: 400;
}

.card-charge-action {
  padding: 2px;
  color: #242057;
  text-decoration: underline;
  font-size: 0.7em;
  font-weight: bold;
  cursor: pointer;
}

.card-charge-action.blue {
  color: #009cff;
}

.card-charge-action.red {
  color: #ee3823;
}

.card-charge-action-arrow {
  font-size: 0.4em;
  font-weight: 300;
}


.home-screen-row {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}

.home-screen-row.rtl {
  direction: rtl;

}

.home-screen-col {

  -webkit-flex: 1 1;

          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 4px;
  position: relative;

}

.home-screen-col.start {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.home-screen-col.end {
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.card-charge-action-arrow {
  padding: 4px;
}

.promo-card-footer {
  font-size: 0.75em;
  width: 20vw;
  color: #232057;
  font-weight: bold
}

.promo-card-footer a {

  color: #009cff;
}

.promo-card-footer.red a {

  color: red;
}



@media (min-width: 1280px) {
  .card-charge-title {
    font-size: 0.85em;
    font-weight: bold;
  }

  .card-charge-action {

    padding: 4px;
    text-decoration: none;
    font-size: 0.75em;
    font-weight: 600;
  }

  .card-charge-action.blue {
    background-color: #009cff;
    color: #ffffff;

  }

  .card-charge-action.red {
    background-color: #ee3823;
    color: #ffffff;

  }


}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {

  .main-screen-container {
    margin-top: 8vh;
  }

  .card-charge-title {
    font-size: 0.85em;
    font-weight: bold;
  }

  .card-charge-action {

    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
    font-size: 0.85em;
    font-weight: 600;
  }

  .card-charge-action.blue {
    background-color: #009cff;
    color: #ffffff;

  }

  .card-charge-action.red {
    background-color: #ee3823;
    color: #ffffff;

  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .home-screen-links {
    font-size: 0.5em;
    margin-top: 10vh;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .home-screen-links {
    padding-top: 5vh;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .home-screen-links {
    padding-top: 1vh;
  }
  .promo-card-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.card-image {
  height: 30vw;
  width: 40vw;
  padding: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  text-decoration: none;
}



.card-image.rtl {
  direction: rtl;


}

.title {
  font-weight: 100;
  color: #ffffff;
  font-size: 1.15em;
  display: block;
}

.subtitle {
  font-weight: 900;
  color: #ffffff;
  font-size: 1.15em;
  display: block;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .card-image {
    width: 18vw;
    height: 10vw;
    padding: 16px;
  }

  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 2em;
  }

  .promo-card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .promo-card-extra {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    text-align: left;
    padding: 20px;
  }

  .promo-card-extra.rtl {
    text-align: right
  }


}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {

  .card-image {
    width: 18vw;
    height: 10vw;
    padding: 16px;
  }

  .promo-card-extra.rtl {
    text-align: right
  }


  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.4em;
  }

  .promo-card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .promo-card-extra {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    text-align: left;
    padding: 20px;
    max-width: 18vw;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .card-image {
    width: 25vw;
    height: 12vw;
    padding: 12px;
  }

  .title {
    font-weight: 100;
    font-size: 1.4em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.4em;
  }



}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .card-image {
    width: 32vw;
    height: 15vw;
    padding: 16px;
  }

  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.5em;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
.category-screen-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  width: 95vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.category-screen-title {
  margin-top: 0.8em;
  font-size: 0.8em;
  margin-bottom: 0.8em;
}


.category-screen-title.rtl {
  direction: rtl
}

.order-screen-footer{
  padding: 10px 0 20px 0;
  text-align: center;
}

#red-txt-btn{
  color: red;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .category-screen-container {
    width: 60vw;
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .category-screen-container {
    width: 60vw;
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}

.tooltip-ltr {
  position: absolute;
  top: 7.5em;
  left: 20vw;
}

.tooltip-rtl {
  position: absolute;
  top: 7.5em;
  right: 14vw;
}

.payment-order-summary {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}


.payment-order-summary.price {
  color: red;
  font-size: 1.1em;
  font-weight: bold;
}

.payment-note {
  color: red;
  font-weight: 200;
  font-size: 0.85em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.payment-note img {
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
  width: 1em;

}

.payment-omni-number-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1em;
}



.payment-omni-number-input-container>input {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;
}

.payment-order-counter {

  margin-bottom: 0.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  font-weight: bold;
}

.payment-order-counter div {
  padding: 0.5em;
}

.payment-order-counter div:first-child {
  background-color: #009cff;
  -webkit-flex: 1 1;
          flex: 1 1;

}

.payment-order-counter div:last-child {

  padding-left: 1em;
  padding-right: 1em;
  background-color: #252058;
}

/*////////////////////////////////////*/
.order-screen-container {
  width: 95%;

}

.order-section ul {
  list-style: none;
  padding: 0;
}

.order-section li {
  line-height: 13px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAATElEQVQImW3NsQmAQBQE0XciVqmhqZlmNqAtmF09V4+pfPBQxImWHdhNpZQOO3okZMwtNkweIp8NhldZGUP8EiI2vxzxsdyndTJjvQD6MgrPrfYCgAAAAABJRU5ErkJggg==) no-repeat right 5px;
}

.order-screen-container.rtl {
  direction: rtl
}

.order-screen-heading {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
}

.order-screen-megaphone {
  background-color: #232255;
  padding: 8px;
  height: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.order-screen-megaphone.rtl {
  direction: rtl;
}

.order-screen-megaphone-text {
  color: white;
  font-size: 15px;
}

.order-screen-megaphone-text.rtl {
  direction: rtl;
}

.order-screen-megaphone-text-bold {
  color: white;
  font-size: 15px;
  font-weight: 900;
}

.order-screen-megaphone-text-bold.rtl {
  direction: rtl;
}

.order-screen-title {
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 1em;
}

.order-strip {
  background-color: #009cff;
  color: #ffffff;
  padding: 0.2em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-size: 0.8em;
}



.order-row {
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.order-total-cell-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0.2em;
  background-color: #eeeeec;
  color: #252058;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-size: 1.2em;
  font-weight: 600;
}

.order-total-cell {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2em;
  -webkit-padding-end: 1em;
          padding-inline-end: 1em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-weight: 800;
  font-size: 1.5em;
  background-color: #252058;
  color: #ffffff;
}

.order-change {
  font-size: 0.8em;
  color: #707070;
  -webkit-margin-before: 2em;
          margin-block-start: 2em;
  -webkit-margin-after: 2em;
          margin-block-end: 2em;
}

.order-change a {
  color: #009cff;
}

.order-change img {
  width: 1em;
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
  height: 1em;
}



/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .order-screen-container {
    width: 64vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .order-section {
    -webkit-flex: 1 1;
            flex: 1 1
  }




  .order-screen-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .order-divider {
    width: 2vw;
  }


  .order-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 0.8em;
    font-weight: bold;
  }

  .order-row.total {
    border-top: 2px solid #232057;
    margin-top: 1em;
    padding-top: 1em;

  }

  .speaker-icon {
    width: 2em;
    height:2em;
  }

  .order-total-cell-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0;
    background: none;
    color: #252058;
    font-size: 1em;
    font-weight: inherit;
  }

  .order-total-cell {

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;

    font-weight: 800;
    font-size: inherit;
    background: none;
    color: #ee3924;
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .order-screen-container {
    width: 70vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
    -webkit-margin-before: 5vh;
            margin-block-start: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .order-screen-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .order-divider {
    width: 1em;
  }

  .order-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 0.8em;
    font-weight: bold;
  }

  .order-row.total {
    border-top: 2px solid #232057;
    margin-top: 1em;
    padding-top: 1em;

  }



  .order-total-cell-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0;
    background: none;
    color: #252058;
    font-size: 1em;
    font-weight: inherit;
  }

  .order-total-cell {

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;

    font-weight: 800;
    font-size: inherit;
    background: none;
    color: #ee3924;
  }

}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .order-screen-container {
    width: 70vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
    -webkit-margin-before: 5vh;
            margin-block-start: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}

.order-screen-container {
  width: 95%;

}

.order-section ul {
  list-style: none;
  padding: 0;
}

.order-section li {
  line-height: 13px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAATElEQVQImW3NsQmAQBQE0XciVqmhqZlmNqAtmF09V4+pfPBQxImWHdhNpZQOO3okZMwtNkweIp8NhldZGUP8EiI2vxzxsdyndTJjvQD6MgrPrfYCgAAAAABJRU5ErkJggg==) no-repeat right 5px;



}

.order-screen-container.rtl {
  direction: rtl
}

.order-screen-heading {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
}


.order-screen-title {
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 1em;
}

.order-strip {
  background-color: #009cff;
  color: #ffffff;
  padding: 0.2em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-size: 0.8em;
}



.order-row {
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.order-total-cell-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0.2em;
  background-color: #eeeeec;
  color: #252058;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-size: 1.2em;
  font-weight: 600;
}

.order-total-cell {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.2em;
  -webkit-padding-end: 1em;
          padding-inline-end: 1em;
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  font-weight: 800;
  font-size: 1.5em;
  background-color: #252058;
  color: #ffffff;
}

.order-change {
  font-size: 0.8em;
  color: #707070;
  -webkit-margin-before: 2em;
          margin-block-start: 2em;
  -webkit-margin-after: 2em;
          margin-block-end: 2em;
}

.order-change a {
  color: #009cff;
}

.order-change img {
  width: 1em;
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
  height: 1em;
}



/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .order-screen-container {
    width: 64vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .order-section {
    -webkit-flex: 1 1;
            flex: 1 1
  }




  .order-screen-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .order-divider {
    width: 2vw;
  }


  .order-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 0.8em;
    font-weight: bold;
  }

  .order-row.no-border {
    margin-top: 1em;
    padding-top: 1em;
  }

  .order-row.total {
    border-top: 2px solid #232057;
    margin-top: 1em;
    padding-top: 1em;
  }

  .order-total-cell-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0;
    background: none;
    color: #252058;
    font-size: 1em;
    font-weight: inherit;
  }

  .order-total-cell {

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;

    font-weight: 800;
    font-size: inherit;
    background: none;
    color: #ee3924;
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .order-screen-container {
    width: 70vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
    -webkit-margin-before: 5vh;
            margin-block-start: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .order-screen-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .order-divider {
    width: 1em;
  }

  .order-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 0.8em;
    font-weight: bold;
  }

  .order-row.total {
    border-top: 2px solid #232057;
    margin-top: 1em;
    padding-top: 1em;

  }



  .order-total-cell-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0;
    background: none;
    color: #252058;
    font-size: 1em;
    font-weight: inherit;
  }

  .order-total-cell {

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;

    font-weight: 800;
    font-size: inherit;
    background: none;
    color: #ee3924;
  }

}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .order-screen-container {
    width: 70vw;
    -webkit-margin-after: 5vh;
            margin-block-end: 5vh;
    -webkit-margin-before: 5vh;
            margin-block-start: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}

.submit-error {
  color: red;
}


.privacy-link a {
  text-decoration: underline
}

.post-purchase-container {
  width: 95%;
  margin-top: 8vh;
  margin-bottom: 8vh;

}

.post-purchase-container.rtl {
  direction: rtl;
}

.thank-you-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}



.thank-you-container img {
  width: 3em;
  height: 3em;
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;
}

.thank-you-container span {
  display: block;
}

.thank-you-container span:first-child {
  font-size: 1.5em;
  font-weight: 900;
  color: #ef3b27;
}

.thank-you-container span:last-child {
  font-size: 1em;
  font-weight: 800;
}


.download-manual {
  margin-top: 1em;
}

.download-manual img {
  margin-top: 0.5em;
  width: 4em;
}

.download-manual span {
  color: #009cff;
  text-decoration: underline;
  font-weight: bold;
}

.post-purchase-contact-form-container {
  margin-top: 1em;
  border: 2px solid #d2d2d4;
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.post-purchase-contact-form-container img {
  width: 1em;
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
}

.post-purchase-contact-form-text>span {
  font-weight: 900;
}

.post-purchase-contact-form-text>div>span {
  -webkit-margin-end: 1em;
          margin-inline-end: 1em;
}


.order-summary-container {}

.order-summary-section {
  margin-top: 1em;
  font-size: 0.95em;
}

.order-summary-section span {
  display: block;
  line-height: 1.5em;
}

.order-summary-items {
  margin-top: 1em;
}


.order-summary-items ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.order-summary-items ul li {
  -webkit-flex: 1 1;
          flex: 1 1;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .post-purchase-container {
    width: 60%;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .post-purchase-container {
    width: 70%;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
.listHorizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: left;
          justify-content: left;
  /* list-style: none; */
}

.countryLi{
    margin: 15px;
}
.admin-rates-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.admin-rates-container.rtl {
  direction: rtl
}

.admin-rates-content-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 2em;
}




.admin-rates-card {
  margin-bottom: 1em;
  border: 1px solid #d2d2d4;
}

.admin-rates-card-body {
  margin-top: 1em;
  margin-bottom: 1em;
}

.admin-rates-card>* {
  padding: 0.3em;
}

.admin-rates-card-header {
  padding: 0.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #d2d2d4;
  background-color: #f6f6f4
}

.admin-rates-card-header>* {
  -webkit-margin-start: 1em;
          margin-inline-start: 1em;
}

.admin-rates-card-header span,
.admin-rates-card-header img {
  color: #a0a09f;
  font-weight: bold;
  cursor: pointer;
}

.admin-rates-card-header h4 {
  margin: 0;
}



.admin-rates-select {
  height: 2.5em;
  -webkit-margin-start: 0.5em;
          margin-inline-start: 0.5em;
  -webkit-margin-end: 1.5em;
          margin-inline-end: 1.5em;
  width: 100%;
}

.admin-rates-button {
  background-color: red;
  color: white;
  border: none;
  font-size: 0.8em;
  padding: 0.9em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  font-weight: bold;
  outline: none;
  margin-left: 1em;
  cursor: pointer !important;
}

.admin-rates-call-info {
  -webkit-margin-end: 0.8em;
          margin-inline-end: 0.8em;
  font-size: 0.8em;
}

.admin-rates-call-info img {
  width: 1.3em;
  -webkit-margin-end: 0.3em;
          margin-inline-end: 0.3em;
}

.admin-rates-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media(min-width: 768px) {
  .admin-rates-action-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .admin-rates-select {
    height: 2.5em;
    -webkit-margin-start: 0.5em;
            margin-inline-start: 0.5em;
    -webkit-margin-end: 1.5em;
            margin-inline-end: 1.5em;
    width: 12vw;
  }

}
.sign-in-container {

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 2em;
}

.sign-in-container.rtl {
  direction: rtl
}

.sign-in-container form {

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sign-in-container form>div {
  -webkit-flex: 1 1;
          flex: 1 1;
  background: tan;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.6em;
  font-size: 0.9em;
}

.sign-in-container form input {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 15vw;
  padding: 0.4em;
}
.admin-container div {
  background-image: none;
}

.admin-container {
  background: #f6f6f4;
}

.admin-container-box {
  width: 95%;
  background: #ffffff;
  border: 2px solid #b8c4c4;
}

.admin-footer {
  text-align: center;
  padding: 0.5em;
  width: 100%;
  margin: 0 auto;
}

.admin-router-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #dce1e0
}



.admin-footer a {
  font-size: 0.8em;
  -webkit-margin-start: 0.5em;
          margin-inline-start: 0.5em;
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
  text-decoration: underline;
}

.admin-panel-router-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
}





@media(min-width: 768px) {
  .admin-container-box {
    width: 65%;

  }

  .admin-container-box {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }

}
.calls-history-container {
  padding: 1em;
  overflow: scroll;
}

.calls-history-container.rtl {
  direction: rtl;
}

.calls-history-form-container {
  min-height: 20vh;
}

.calls-history-form-container>* {
  margin-bottom: 0.6em;

}

.calls-history-form-container input {
  -webkit-margin-start: 0.6em;
          margin-inline-start: 0.6em;
  height: 2em;
}

.call-history-sim-card-select {
  width: 250px;

}

.calls-history-form-container button {
  cursor: pointer;
  display: block;
  background-color: #ee3823;
  color: white;
  outline: none;
  border: none;
  font-size: 0.8em;
  font-weight: bold;
  padding-right: 1.5em;
  padding-left: 1.5em;
  height: 2em;
}

.calls-history-form-container .inverse {

  background-color: #ffffff;
  color: #ee3823;
  border: 1px solid #ee3823;
}
.omni-table tr,
.omni-table td {
  border: 1px solid #d2d2d4;
  text-align: center;
  padding: 8px;
}

.omni-table {
  border-collapse: collapse;
  margin: 0 auto;
}

.omni-table thead tr {
  background-color: #f6f6f4;
}

.omni-table thead td {
  padding: 0.5em;
}
.admin-status-container {
  margin: 0 auto;
  width: 90%;
  padding-bottom: 2em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.admin-status-container.rtl {
  direction: rtl;
}

.admin-status-header {
  border-bottom: 1px solid #dce1e0;
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}

.admin-status-header h3 {
  font-size: 1em;
  margin: 0.5em;
}

.admin-status-table td {
  padding-bottom: 0.4em;
  line-height: 2em;
}

.admin-status-table td .omni-input {
  width: 25vw;
}

.admin-status-expiration-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.admin-status-expiration-container .omni-input {
  width: 11vw !important;
}

.admin-credits-table {
  min-width: 350px;
  text-align: center;
}

.admin-status-progress {
  width: 50%;
  margin: 0 auto;
}

@media(max-width:768px) {
  .admin-status-table tr {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
  }

  .admin-status-table td {
    line-height: 1em;

  }

  .admin-status-table td .omni-input {
    width: 100%;
  }

  .admin-status-expiration-container .omni-input {
    width: 45% !important;
  }
}
.purchase-history-container {
  overflow: scroll;
  padding: 1em;
}

.purchase-history-container.rtl {
  direction: rtl;
}



.purchase-history-form-container>* {
  margin-bottom: 0.6em;
}

.purchase-history-form-container input {
  -webkit-margin-start: 0.6em;
          margin-inline-start: 0.6em;
  height: 2em;
}

.purchase-history-form-container button {
  cursor: pointer;
  display: block;
  background-color: #ee3823;
  color: white;
  outline: none;
  border: none;
  font-size: 0.8em;
  font-weight: bold;
  padding-right: 1.5em;
  padding-left: 1.5em;
  height: 2em;
}
.omni-page {
  margin: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-weight: 400;
}



@media(min-width: 768px) {
  .omni-page {
    margin: 40px 20px 20px 20px
  }

  .omni-page>h1 {
    -webkit-align-self: center;
            align-self: center;
    margin-bottom: 100px;
    font-size: 2.2em;
  }
}

@media(min-width: 1024px) {
  .omni-page {
    margin: 40px 0px 100px 0px;
  }
}

.page-container-contactUs{
    padding-top: 0;
}
.page-container-contactUs-ty{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 300px;
}

.contactUs-title{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 70px;
}

.contactUs-title> p{
    margin: 2px 0;
}

.contactUs-content{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.contact-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 30px;
    font-size: 20px;
}

.contact-info-element{
    display: -webkit-flex;
    display: flex;
    padding-top: 20px;
}
.contact-icon{
    padding: 0 20px 0 20px;
}
.separator{
    border: 1px solid rgb(0, 156, 255);
    margin-left: 35px;
    height: 290.5px;
}
.separator.rtl{
    margin-left: 0px;
    margin-right: 35px;
}
.markedText{
    color: rgb(0, 156, 255);
}
.contactUs-heading{
    padding-top: 50px;
    padding-bottom: 20px;
}
.decorated-heading{
    position: relative;
    font-weight: 900;
    margin-top: 60px;
    font-size: 40px;
}
.star-icon{
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    top: -20px;
    right: 220px;
}
.star-icon.rtl{
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    top: -20px;
    right: -45px;
}
.contactUs-ty-content{
    position: relative;
}
.star-icon-ty{
    width: 1em;
    height: 1em;
    position: absolute;
    top: -20px;
    right: 670px;
}
.star-icon-ty.rtl{
    width: 1em;
    height: 1em;
    position: absolute;
    top: -20px;
    right: -30px;
}

@media (max-width: 1024px) {

    .contactUs-content{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .fieldStyle{
        -webkit-align-items: center;
                align-items: center;
    }
    .formFooter{
        -webkit-justify-content: center;
                justify-content: center;
    }
    .contact-info{
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
        padding-bottom: 30px;
    }
    .separator{
        display: none;
    }

}

@media (max-width: 767px){
    .contact-info{
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-bottom: 10px;
    }
    .contact-icon{
        padding: 0 20px 0 5px
    }
}

@media (max-width: 360px){
    .star-icon-ty.rtl{
        width: 1em;
        height: 1em;
        position: absolute;
        top: -10px;
        right: -20px;
    }
    .contact-info{
        font-size: 17px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .star-icon-ty{
        width: 1em;
        height: 1em;
        position: absolute;
        top: -20px;
        right: 450px;
    }
    .page-container-contactUs-ty{
        -webkit-flex-direction: column;
                flex-direction: column;
        font-size: 20px;
        padding-top: 200px;
    }
}

@media  (max-width: 480px) {
    .star-icon-ty{
        width: 1em;
        height: 1em;
        position: absolute;
        top: -15px;
        right: 325px;
    }
    .page-container-contactUs-ty{
        -webkit-flex-direction: column;
                flex-direction: column;
        font-size: 15px;
        padding-top: 200px;
    }
    .separator{
        display: block;
        border: 1px solid rgb(0, 156, 255);
        margin: 30px 0 0 0;
        height: 0;
    }
    .separator.rtl{
        margin: 30px 0 0 0;
    }
}

.ContactUsForm{
}
.fieldStyle{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 15px;
}
.textAreaStyle{
    padding-top: 20px;
    height: 100px;
    resize: none;
}
.inputStyle{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: rgb(225, 225, 225);
    color: black;
    font-size: 20px;
    width: 25vw;
    max-width: 700px;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    box-shadow: none;
    border: none;
}
::-webkit-input-placeholder{
    color: rgb(157, 157, 157);
}
::-ms-input-placeholder{
    color: rgb(157, 157, 157);
}
::placeholder{
    color: rgb(157, 157, 157);
}
.inputError{
    font-size: 15px;
    font-weight: 700;
    color: brown;
}
.submitButton{
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(0, 156, 255);
    width: 50%;
    min-height: 50px;
    border: none;
}
.submitButton:hover{
    background-color: rgb(0, 134, 217);
}

.submitButton:disabled{
    background-color: grey;
    cursor: wait;
}
/* .submitButton:active{
    cursor: progress;
} */
.formFooter{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

@media (max-width: 1024px) {

    .inputStyle{
        width: 90%;
    }

}
