.omni-input {
  background-color: #ffffff;
  border: 1px solid #cececd;
  padding: 0.4em;
}

.omni-input-container {
  flex: inherit;

}

.omni-input-error {
  color: red;
  font-size: 0.8em;
}
