.screen-card-title {
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 1em;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {




  .screen-card-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }




  .screen-card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .screen-card-content>* {
    margin-inline-start: 1em;
    margin-inline-end: 1em;
  }

  .screen-card-content>*:first-child {
    margin-inline-start: 0;
  }

  .screen-card-content>*:last-child {
    margin-inline-end: 0;
  }



}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {


  .screen-card-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .screen-card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }


  .screen-card-content>* {
    margin-inline-start: 1em;
    margin-inline-end: 1em;
  }

  .screen-card-content>*:first-child {
    margin-inline-start: 0;
  }

  .screen-card-content>*:last-child {
    margin-inline-end: 0;
  }


}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .order-screen-container {
    width: 70vw;
    margin-block-end: 5vh;
    margin-block-start: 5vh;
  }

  .screen-card-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}