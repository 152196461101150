.main-background-image {
  height: 8vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url('../../assets/background1-mobile.png')
}

.bottom-background-image {
  height: 10vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url('../../assets/background2-mobile.png')
}



.footer-background {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.screen-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(#f0f0f0, #ffffff, #ffffff, #ffffff)
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .main-background-image {
    display: none;
  }

  .bottom-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url('../../assets/background2.png')
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .main-background-image {
    display: none;
  }

  .bottom-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url('../../assets/background2.png')
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .main-background-image {
    display: none;
  }

  .bottom-background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url('../../assets/background2.png')
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-background-image {
    width: 60vw;
  }
}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .main-background-image {
    width: 70vw;
  }
}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
