.omni-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.7em;
  height: 0.7em;
  border-radius: 0.35em;
  background-color: #ffffff;
  border: 1px solid #e1e0e5;
  margin-inline-end: 0.35em;
}

.omni-radio-selected {

  width: 0.4em;
  height: 0.4em;
  border-radius: 0.22em;
  background-color: #ee3725;
}


.omni-radio-group {
  margin-top: 0.2em;
  margin-bottom: 0.2em;

}

.omni-radio-group>* {
  margin-top: 0.5em;


}

.omni-radio-group input {
  margin-inline-end: 0.8em;

}

.omni-radio-option {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-inline-end: 16px;
}