.order-form-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



@media(min-width:768px) {
  .order-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .order-form-row>*:last-child {
    margin-inline-start: 1em;
  }



}

@media(min-width:1024px) {


  .order-form-row--responsive {
    flex-direction: row;
    display: flex;
  }

  .order-form-row--responsive>*:first-child {
    margin-inline-end: 10px;
  }
}


.cvv-row-container {
  display: flex;
  position: relative;
}

#cvv-hover:hover .cvv-message-container {
  display: block;

}

.cvv-message-container {
  display: none;
  border-radius: 15px;
  box-shadow: 3px 3px 5px grey;
  top: -50px;
  position: absolute;
  width: 250px;
  height: 100px;
  border: 1px solid grey;
  background-color: white;
  z-index: 100;
  padding: 6px;
}



.cvv-image {

  margin-inline-end: 0.5em;

  width: 25px;
  height: 28px;
  align-self: center;
}

.container-relative {
  position: relative;
}



.order-form-row input {
  margin-bottom: 1.3em;
}

.order-form-checkbox {
  -webkit-appearance: none;
  width: 1.3em;
  margin-inline-end: 1em;
  height: 1.3em;
  background-color: #ffffff;
  border: 1px solid #d2d2d4;
  display: inline-block;
  position: relative;
}

.order-form-checkbox:checked {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  background-color: #009cff;
  color: #ffffff;
}

.order-form-checkbox:checked:after {
  content: '\2713';
  font-size: 1.1em;
  font-weight: bold;
  position: absolute;
  bottom: -1px;
  left: 0px;
  color: #ffffff;
}

.order-form-card-number {
  display: flex;
  flex-direction: column;
}

.order-form-card-number label {
  margin-block-end: 0.2em;
}

.order-form-row-container {
  margin-block-start: 1em;
}

.order-form-input-expiration {
  width: 10vw;
}

.order-form-terms {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #51514f;
  font-size: 0.8em;
}

.order-form-errors {
  color: #ee3924;
  font-size: 0.8em;

}