.admin-rates-link {
  display: block;
  font-weight: bold;
  text-align: center;
}

.admin-rates-link div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10vw;
  padding: 1em;

}

.admin-rates-link div>img {
  display: none;
  width: 2em;

}

.admin-rates-link-active {
  font-weight: normal;
  color: #ffffff;
}

.admin-rates-link-active div {
  background-color: #ee3823;
}

.admin-rates-link-active div>img {
  display: block;
}

.admin-rates-navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
}