.category-screen-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 95vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.category-screen-title {
  margin-top: 0.8em;
  font-size: 0.8em;
  margin-bottom: 0.8em;
}


.category-screen-title.rtl {
  direction: rtl
}

.order-screen-footer{
  padding: 10px 0 20px 0;
  text-align: center;
}

#red-txt-btn{
  color: red;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .category-screen-container {
    width: 60vw;
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .category-screen-container {
    width: 60vw;
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
