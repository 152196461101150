.custom-footer{
    width: 100%;
    z-index: 1;
    text-align: center;
    bottom: 0;
}

.contactUs-footer{
    background-color: rgb(227, 35, 12);
    color: white;
}