.admin-rates-container {
  display: flex;
  flex-direction: row;
}

.admin-rates-container.rtl {
  direction: rtl
}

.admin-rates-content-container {
  flex: 1;
  padding: 2em;
}




.admin-rates-card {
  margin-bottom: 1em;
  border: 1px solid #d2d2d4;
}

.admin-rates-card-body {
  margin-top: 1em;
  margin-bottom: 1em;
}

.admin-rates-card>* {
  padding: 0.3em;
}

.admin-rates-card-header {
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d2d2d4;
  background-color: #f6f6f4
}

.admin-rates-card-header>* {
  margin-inline-start: 1em;
}

.admin-rates-card-header span,
.admin-rates-card-header img {
  color: #a0a09f;
  font-weight: bold;
  cursor: pointer;
}

.admin-rates-card-header h4 {
  margin: 0;
}



.admin-rates-select {
  height: 2.5em;
  margin-inline-start: 0.5em;
  margin-inline-end: 1.5em;
  width: 100%;
}

.admin-rates-button {
  background-color: red;
  color: white;
  border: none;
  font-size: 0.8em;
  padding: 0.9em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  font-weight: bold;
  outline: none;
  margin-left: 1em;
  cursor: pointer !important;
}

.admin-rates-call-info {
  margin-inline-end: 0.8em;
  font-size: 0.8em;
}

.admin-rates-call-info img {
  width: 1.3em;
  margin-inline-end: 0.3em;
}

.admin-rates-action-row {
  display: flex;
  flex-direction: column;
}

@media(min-width: 768px) {
  .admin-rates-action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .admin-rates-select {
    height: 2.5em;
    margin-inline-start: 0.5em;
    margin-inline-end: 1.5em;
    width: 12vw;
  }

}