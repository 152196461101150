.admin-status-container {
  margin: 0 auto;
  width: 90%;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-status-container.rtl {
  direction: rtl;
}

.admin-status-header {
  border-bottom: 1px solid #dce1e0;
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}

.admin-status-header h3 {
  font-size: 1em;
  margin: 0.5em;
}

.admin-status-table td {
  padding-bottom: 0.4em;
  line-height: 2em;
}

.admin-status-table td .omni-input {
  width: 25vw;
}

.admin-status-expiration-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.admin-status-expiration-container .omni-input {
  width: 11vw !important;
}

.admin-credits-table {
  min-width: 350px;
  text-align: center;
}

.admin-status-progress {
  width: 50%;
  margin: 0 auto;
}

@media(max-width:768px) {
  .admin-status-table tr {
    flex-direction: column;
    display: flex;
  }

  .admin-status-table td {
    line-height: 1em;

  }

  .admin-status-table td .omni-input {
    width: 100%;
  }

  .admin-status-expiration-container .omni-input {
    width: 45% !important;
  }
}