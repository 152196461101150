.footer {
  z-index: 5;
  bottom: 0;
  width: 100%;
  background-color: #dce0df;
  /* font-size: 0.8em; */
  font-size: 0.5em; 
  color: #242158;
}

.footer.rtl {
  direction: rtl;
}

.footer-credits {
  /* margin: 0 auto;
  padding: 2em; */
  font-size: 0.4em; /*was added*/
  font-weight: bold;
}

.footer-credits a {
  text-decoration: none;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}



.footer-credits img {
  width: 1.5em;
  margin-inline-end: 0.5em;
}

.underline {

  text-decoration: underline;
}

.footer-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  padding: 12px;
  font-size: 0.8rem;
  /* min-height: 8vh; */
}

.footer-container span {
  padding: 2px;
}

.heading {
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 1.2em;

}

.footer-contact-container {
  display: flex;
  flex-direction: column;
}



@media (min-width: 1281px) {

  .footer-contact-container {
    flex-direction: row;
  }


}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}