.screen-card-section-strip {
  background-color: #009cff;
  color: #ffffff;
  padding: 0.2em;
  padding-inline-start: 1em;
  font-size: 1em;
}


.screen-card-section {
  flex: 1
}

.screen-card-section ul {
  list-style: none;
  padding: 0;
}

.screen-card-section li {
  line-height: 13px;
  background: url('../../assets/bullet.png') no-repeat left 5px;
}

.screen-card-section ul.rtl li {
  background: url('../../assets/bullet.png') no-repeat right 5px;
}


@media (min-width: 1281px) {
  .screen-card-section-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 1em;
    font-weight: 900;
  }



}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {


  .screen-card-section-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 1em;
    font-weight: 900;
  }



}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
