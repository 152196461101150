.screen-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen-title-content {
  width: 95vw;
  padding: 0.8em;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.3em;
  font-weight: bold;
}

.screen-title-content.rtl {
  direction: rtl;
}

.screen-title-image {

  right: 0;

}



@media (min-width: 1280px) {
  .screen-title-content {
    width: 60vw;
    font-size: 2em;
    padding: 0.8em;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .screen-title-content {
    width: 60vw;
    font-size: 2em;
    padding: 0.8em;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .screen-title-content {
    width: 95vw;
    font-size: 1.8em;
    padding: 0.6em;
  }


}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .card-image {
    width: 32vw;
    height: 15vw;
    padding: 16px;
  }

  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.5em;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}