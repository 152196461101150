.ContactUsForm{
}
.fieldStyle{
    display:flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.textAreaStyle{
    padding-top: 20px;
    height: 100px;
    resize: none;
}
.inputStyle{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: rgb(225, 225, 225);
    color: black;
    font-size: 20px;
    width: 25vw;
    max-width: 700px;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    box-shadow: none;
    border: none;
}
::placeholder{
    color: rgb(157, 157, 157);
}
.inputError{
    font-size: 15px;
    font-weight: 700;
    color: brown;
}
.submitButton{
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(0, 156, 255);
    width: 50%;
    min-height: 50px;
    border: none;
}
.submitButton:hover{
    background-color: rgb(0, 134, 217);
}

.submitButton:disabled{
    background-color: grey;
    cursor: wait;
}
/* .submitButton:active{
    cursor: progress;
} */
.formFooter{
    display: flex;
    flex-direction: row-reverse;
}

@media (max-width: 1024px) {

    .inputStyle{
        width: 90%;
    }

}