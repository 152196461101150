.omni-button {
  background-color: #ee3924;
  color: #ffffff;
  border: none;
  width: 100%;
  padding: 0.8em;
  font-weight: 800;
  font-size: 1.2em;
}

.omni-button.success {
  background-color: #38af12;
}

.omni-button.disabled {
  background-color: #707070;
}