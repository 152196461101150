.omni-page {
  margin: 16px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
}



@media(min-width: 768px) {
  .omni-page {
    margin: 40px 20px 20px 20px
  }

  .omni-page>h1 {
    align-self: center;
    margin-bottom: 100px;
    font-size: 2.2em;
  }
}

@media(min-width: 1024px) {
  .omni-page {
    margin: 40px 0px 100px 0px;
  }
}
