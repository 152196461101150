.page-container-contactUs{
    padding-top: 0;
}
.page-container-contactUs-ty{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 300px;
}

.contactUs-title{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 70px;
}

.contactUs-title> p{
    margin: 2px 0;
}

.contactUs-content{
    display: flex;
    justify-content: center;
}

.contact-info{
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    font-size: 20px;
}

.contact-info-element{
    display: flex;
    padding-top: 20px;
}
.contact-icon{
    padding: 0 20px 0 20px;
}
.separator{
    border: 1px solid rgb(0, 156, 255);
    margin-left: 35px;
    height: 290.5px;
}
.separator.rtl{
    margin-left: 0px;
    margin-right: 35px;
}
.markedText{
    color: rgb(0, 156, 255);
}
.contactUs-heading{
    padding-top: 50px;
    padding-bottom: 20px;
}
.decorated-heading{
    position: relative;
    font-weight: 900;
    margin-top: 60px;
    font-size: 40px;
}
.star-icon{
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    top: -20px;
    right: 220px;
}
.star-icon.rtl{
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    top: -20px;
    right: -45px;
}
.contactUs-ty-content{
    position: relative;
}
.star-icon-ty{
    width: 1em;
    height: 1em;
    position: absolute;
    top: -20px;
    right: 670px;
}
.star-icon-ty.rtl{
    width: 1em;
    height: 1em;
    position: absolute;
    top: -20px;
    right: -30px;
}

@media (max-width: 1024px) {

    .contactUs-content{
        flex-direction: column;
    }
    .fieldStyle{
        align-items: center;
    }
    .formFooter{
        justify-content: center;
    }
    .contact-info{
        flex-direction: row;
        justify-content: center;
        padding-bottom: 30px;
    }
    .separator{
        display: none;
    }

}

@media (max-width: 767px){
    .contact-info{
        flex-direction: column;
        padding-bottom: 10px;
    }
    .contact-icon{
        padding: 0 20px 0 5px
    }
}

@media (max-width: 360px){
    .star-icon-ty.rtl{
        width: 1em;
        height: 1em;
        position: absolute;
        top: -10px;
        right: -20px;
    }
    .contact-info{
        font-size: 17px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .star-icon-ty{
        width: 1em;
        height: 1em;
        position: absolute;
        top: -20px;
        right: 450px;
    }
    .page-container-contactUs-ty{
        flex-direction: column;
        font-size: 20px;
        padding-top: 200px;
    }
}

@media  (max-width: 480px) {
    .star-icon-ty{
        width: 1em;
        height: 1em;
        position: absolute;
        top: -15px;
        right: 325px;
    }
    .page-container-contactUs-ty{
        flex-direction: column;
        font-size: 15px;
        padding-top: 200px;
    }
    .separator{
        display: block;
        border: 1px solid rgb(0, 156, 255);
        margin: 30px 0 0 0;
        height: 0;
    }
    .separator.rtl{
        margin: 30px 0 0 0;
    }
}
