.tooltip-ltr {
  position: absolute;
  top: 7.5em;
  left: 20vw;
}

.tooltip-rtl {
  position: absolute;
  top: 7.5em;
  right: 14vw;
}

.payment-order-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.payment-order-summary.price {
  color: red;
  font-size: 1.1em;
  font-weight: bold;
}

.payment-note {
  color: red;
  font-weight: 200;
  font-size: 0.85em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.payment-note img {
  margin-inline-end: 0.5em;
  width: 1em;

}

.payment-omni-number-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}



.payment-omni-number-input-container>input {
  flex: 1;
  margin-inline-end: 1em;
}

.payment-order-counter {

  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
}

.payment-order-counter div {
  padding: 0.5em;
}

.payment-order-counter div:first-child {
  background-color: #009cff;
  flex: 1;

}

.payment-order-counter div:last-child {

  padding-left: 1em;
  padding-right: 1em;
  background-color: #252058;
}

/*////////////////////////////////////*/
.order-screen-container {
  width: 95%;

}

.order-section ul {
  list-style: none;
  padding: 0;
}

.order-section li {
  line-height: 13px;
  background: url('../../assets/bullet.png') no-repeat right 5px;
}

.order-screen-container.rtl {
  direction: rtl
}

.order-screen-heading {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
}

.order-screen-megaphone {
  background-color: #232255;
  padding: 8px;
  height: 20px;
  display: flex;
  align-items: center;
}

.order-screen-megaphone.rtl {
  direction: rtl;
}

.order-screen-megaphone-text {
  color: white;
  font-size: 15px;
}

.order-screen-megaphone-text.rtl {
  direction: rtl;
}

.order-screen-megaphone-text-bold {
  color: white;
  font-size: 15px;
  font-weight: 900;
}

.order-screen-megaphone-text-bold.rtl {
  direction: rtl;
}

.order-screen-title {
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 1em;
}

.order-strip {
  background-color: #009cff;
  color: #ffffff;
  padding: 0.2em;
  padding-inline-start: 1em;
  font-size: 0.8em;
}



.order-row {
  margin-block-end: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-total-cell-title {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0.2em;
  background-color: #eeeeec;
  color: #252058;
  padding-inline-start: 1em;
  font-size: 1.2em;
  font-weight: 600;
}

.order-total-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  padding-inline-end: 1em;
  padding-inline-start: 1em;
  font-weight: 800;
  font-size: 1.5em;
  background-color: #252058;
  color: #ffffff;
}

.order-change {
  font-size: 0.8em;
  color: #707070;
  margin-block-start: 2em;
  margin-block-end: 2em;
}

.order-change a {
  color: #009cff;
}

.order-change img {
  width: 1em;
  margin-inline-end: 0.5em;
  height: 1em;
}



/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .order-screen-container {
    width: 64vw;
    margin-block-end: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .order-section {
    flex: 1
  }




  .order-screen-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .order-divider {
    width: 2vw;
  }


  .order-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 0.8em;
    font-weight: bold;
  }

  .order-row.total {
    border-top: 2px solid #232057;
    margin-top: 1em;
    padding-top: 1em;

  }

  .speaker-icon {
    width: 2em;
    height:2em;
  }

  .order-total-cell-title {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    background: none;
    color: #252058;
    font-size: 1em;
    font-weight: inherit;
  }

  .order-total-cell {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    font-weight: 800;
    font-size: inherit;
    background: none;
    color: #ee3924;
  }
}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .order-screen-container {
    width: 70vw;
    margin-block-end: 5vh;
    margin-block-start: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }

  .order-screen-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .order-divider {
    width: 1em;
  }

  .order-strip {
    background: none;
    color: #241f57;
    border-bottom: 1px solid #241f57;
    padding: 0.2em;
    font-size: 0.8em;
    font-weight: bold;
  }

  .order-row.total {
    border-top: 2px solid #232057;
    margin-top: 1em;
    padding-top: 1em;

  }



  .order-total-cell-title {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0;
    background: none;
    color: #252058;
    font-size: 1em;
    font-weight: inherit;
  }

  .order-total-cell {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    font-weight: 800;
    font-size: inherit;
    background: none;
    color: #ee3924;
  }

}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .order-screen-container {
    width: 70vw;
    margin-block-end: 5vh;
    margin-block-start: 5vh;
  }

  .order-screen-content-container {

    background-color: #ffffff;

    border: 2px solid #009cff;
    padding: 2em;
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
