.header {
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.omni-logo {
  cursor: pointer;
}

.header-container {
  width: 100%;
  min-height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-container .omni-logo img {
  width: 35vw;
}

.header-container .local03-logo img {
  width: 25vw;
}

.header-end {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  }

  .header-container {
    width: 90vw;

  }

}

/*
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  }
}

/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  } */
}

/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* .header-container .omni-logo img,
  .header-container .local03-logo img {
    width: inherit;
  } */
}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}
