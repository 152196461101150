.post-purchase-container {
  width: 95%;
  margin-top: 8vh;
  margin-bottom: 8vh;

}

.post-purchase-container.rtl {
  direction: rtl;
}

.thank-you-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}



.thank-you-container img {
  width: 3em;
  height: 3em;
  margin-inline-end: 1em;
}

.thank-you-container span {
  display: block;
}

.thank-you-container span:first-child {
  font-size: 1.5em;
  font-weight: 900;
  color: #ef3b27;
}

.thank-you-container span:last-child {
  font-size: 1em;
  font-weight: 800;
}


.download-manual {
  margin-top: 1em;
}

.download-manual img {
  margin-top: 0.5em;
  width: 4em;
}

.download-manual span {
  color: #009cff;
  text-decoration: underline;
  font-weight: bold;
}

.post-purchase-contact-form-container {
  margin-top: 1em;
  border: 2px solid #d2d2d4;
  padding: 1em;
  display: flex;
  flex-direction: row;
}

.post-purchase-contact-form-container img {
  width: 1em;
  margin-inline-end: 0.5em;
}

.post-purchase-contact-form-text>span {
  font-weight: 900;
}

.post-purchase-contact-form-text>div>span {
  margin-inline-end: 1em;
}


.order-summary-container {}

.order-summary-section {
  margin-top: 1em;
  font-size: 0.95em;
}

.order-summary-section span {
  display: block;
  line-height: 1.5em;
}

.order-summary-items {
  margin-top: 1em;
}


.order-summary-items ul {
  display: flex;
  flex-wrap: wrap;
}

.order-summary-items ul li {
  flex: 1;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .post-purchase-container {
    width: 60%;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
  .post-purchase-container {
    width: 70%;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}