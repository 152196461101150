.main-content {
  width: 100%;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}



@media(min-width: 768px) {
  .main-content {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url('../../assets/background1.png')
  }
}

@media (min-width:1240px) {



  .main-content {
    z-index: 1;
    /* padding-bottom: 200px; */
  }
}