.shipping-info-form {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.shipping-info-form>div.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5em;
}

.shipping-country-select {
  flex: 9.3;

}

.pickup-container {
  border: 1px solid #009cff;
  padding: 4px 8px;
  margin-bottom: 8px;
}