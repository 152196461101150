.calls-history-container {
  padding: 1em;
  overflow: scroll;
}

.calls-history-container.rtl {
  direction: rtl;
}

.calls-history-form-container {
  min-height: 20vh;
}

.calls-history-form-container>* {
  margin-bottom: 0.6em;

}

.calls-history-form-container input {
  margin-inline-start: 0.6em;
  height: 2em;
}

.call-history-sim-card-select {
  width: 250px;

}

.calls-history-form-container button {
  cursor: pointer;
  display: block;
  background-color: #ee3823;
  color: white;
  outline: none;
  border: none;
  font-size: 0.8em;
  font-weight: bold;
  padding-right: 1.5em;
  padding-left: 1.5em;
  height: 2em;
}

.calls-history-form-container .inverse {

  background-color: #ffffff;
  color: #ee3823;
  border: 1px solid #ee3823;
}