.admin-tab-bar-selected {
  background-color: #454083;
}

.admin-tab-bar {
  font-size: 0.85em;
  color: #ffffff;
  background-color: #242057;
  font-weight: bold;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.admin-tab-bar a {
  padding: 0.6em;
  flex: 1;
  text-align: center;
}