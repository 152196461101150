body {

  color: #242057;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Hebrew', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline
}

.column {
  display: flex;
  flex-direction: column;
}

span.blue {
  color: #009cff;
}

span.red {
  color: #ee3924;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row.stretch {
  justify-content: space-between;
}

.row.center {
  justify-content: center;
}

.row.spread {
  justify-content: space-around
}

.row.space>* {
  margin-inline-end: 1em;
}


.omni-checkbox {
  -webkit-appearance: none;
  width: 1.3em;
  height: 1.3em !important;
  margin-inline-end: 1em;
  background-color: #ffffff;
  border: 1px solid #d2d2d4;
  display: inline-block;
  position: relative;
  outline: none;
}

.omni-checkbox:checked {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  background-color: #009cff;
  color: #ffffff;
}

.omni-checkbox:checked:after {
  content: '\2713';
  font-size: 1.1em;
  font-weight: bold;
  position: absolute;
  bottom: -1px;
  left: 0px;
  color: #ffffff;
}

.omni-select {
  min-width: 200px;
}