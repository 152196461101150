.omni-table tr,
.omni-table td {
  border: 1px solid #d2d2d4;
  text-align: center;
  padding: 8px;
}

.omni-table {
  border-collapse: collapse;
  margin: 0 auto;
}

.omni-table thead tr {
  background-color: #f6f6f4;
}

.omni-table thead td {
  padding: 0.5em;
}