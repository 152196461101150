.navigation-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  font-weight: bold;
  border-top: 1px solid #9a9c96;
  padding-top: 10px;
}

.navigation-container.rtl {
  direction: rtl;
}


.disabled-link {
  pointer-events: none;
}

.navigation-container-active {
  color: #38af12;
}

.navigation-container-disabled {
  cursor: pointer;
  background: none;
  border: none;
  font: inherit;
  text-decoration: underline;
  color: #707070;
}

.navigation-container-disabled:focus {
  outline: 0;

}

.navigation-arrow {
  padding: 0.2em;
  font-size: 0.5em;
}