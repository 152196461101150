.sign-in-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em;
}

.sign-in-container.rtl {
  direction: rtl
}

.sign-in-container form {

  display: flex;
  flex-direction: column;
}

.sign-in-container form>div {
  flex: 1;
  background: tan;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.6em;
  font-size: 0.9em;
}

.sign-in-container form input {
  flex: 1;
  width: 15vw;
  padding: 0.4em;
}