.admin-container div {
  background-image: none;
}

.admin-container {
  background: #f6f6f4;
}

.admin-container-box {
  width: 95%;
  background: #ffffff;
  border: 2px solid #b8c4c4;
}

.admin-footer {
  text-align: center;
  padding: 0.5em;
  width: 100%;
  margin: 0 auto;
}

.admin-router-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #dce1e0
}



.admin-footer a {
  font-size: 0.8em;
  margin-inline-start: 0.5em;
  margin-inline-end: 0.5em;
  text-decoration: underline;
}

.admin-panel-router-container {
  flex: 1;
  width: 100%;
}





@media(min-width: 768px) {
  .admin-container-box {
    width: 65%;

  }

  .admin-container-box {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }

}