.textfield-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.textfield-label {
  margin-inline-end: 1em;

}



.textfield-input {
  flex: 1;
}

.input-error {
  border: 1px solid red;
}