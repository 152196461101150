.main-screen-container {
  width: 90vw;
  padding: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2vh;
  text-align: center;
}

.main-screen-heading {
  font-weight: bold;
  color: #242057;
  font-size: 1.7em;
}

.main-screen-heading h2 {
  font-size: 1.3em;
  font-weight: 800;
  margin: 0;
}

.main-screen-heading h1 {
  font-size: 1.4em;
  font-weight: 500;
  margin: 0;
}

.card-charge-title {
  margin-top: 4px;
  display: block;
  color: #242057;
  font-size: 0.8em;
  font-weight: 400;
}

.card-charge-action {
  padding: 2px;
  color: #242057;
  text-decoration: underline;
  font-size: 0.7em;
  font-weight: bold;
  cursor: pointer;
}

.card-charge-action.blue {
  color: #009cff;
}

.card-charge-action.red {
  color: #ee3823;
}

.card-charge-action-arrow {
  font-size: 0.4em;
  font-weight: 300;
}


.home-screen-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.home-screen-row.rtl {
  direction: rtl;

}

.home-screen-col {

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4px;
  position: relative;

}

.home-screen-col.start {
  align-items: flex-end;
}

.home-screen-col.end {
  align-items: flex-start
}

.card-charge-action-arrow {
  padding: 4px;
}

.promo-card-footer {
  font-size: 0.75em;
  width: 20vw;
  color: #232057;
  font-weight: bold
}

.promo-card-footer a {

  color: #009cff;
}

.promo-card-footer.red a {

  color: red;
}



@media (min-width: 1280px) {
  .card-charge-title {
    font-size: 0.85em;
    font-weight: bold;
  }

  .card-charge-action {

    padding: 4px;
    text-decoration: none;
    font-size: 0.75em;
    font-weight: 600;
  }

  .card-charge-action.blue {
    background-color: #009cff;
    color: #ffffff;

  }

  .card-charge-action.red {
    background-color: #ee3823;
    color: #ffffff;

  }


}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {

  .main-screen-container {
    margin-top: 8vh;
  }

  .card-charge-title {
    font-size: 0.85em;
    font-weight: bold;
  }

  .card-charge-action {

    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
    font-size: 0.85em;
    font-weight: 600;
  }

  .card-charge-action.blue {
    background-color: #009cff;
    color: #ffffff;

  }

  .card-charge-action.red {
    background-color: #ee3823;
    color: #ffffff;

  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .home-screen-links {
    font-size: 0.5em;
    margin-top: 10vh;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .home-screen-links {
    padding-top: 5vh;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .home-screen-links {
    padding-top: 1vh;
  }
  .promo-card-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}