.page-container {
  margin: 0 auto;
  padding-top: 6vh;
  width: 95%;
}

.page-title {
  background-color: #242057;
  padding: 0.2em;
  font-weight: bold;
  color: #ffffff;
  font-size: 0.9em;
  padding-inline-start: 1em;

}

.page-content {
  padding: 1em;
}

.page-container.rtl {
  direction: rtl;
}

@media(min-width: 768px) {
  .page-container {

    width: 60%;


  }
}