.package-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.package-card-content {
  display: flex;
  width: 100%;
  background-color: #ffffff;

  flex-direction: row;
  justify-content: space-between;
}

.package-card-content.rtl {
  direction: rtl;
}

.package-card-info-container {
  display: flex;
  padding: 0.4em;
  flex-direction: column;

  border: 2px solid #009cff;
  border-inline-end-width: 0;
  flex: 3;
}



.package-card-title {
  color: #009cff;
  font-size: 1.3em;
  font-weight: 800;
}

.package-card-subtitle {
  color: #ee3823;
  font-size: 1.6em;
  font-weight: 800;
}

.package-card-extra-info {
  color: #242057;
  font-size: 0.8em;
  font-weight: 200;
}

.package-card-extra-info ul {
  list-style-image: url('../../assets/bullet.png');
  list-style-position: inside;
  padding: 0;
}



.package-card-form-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.8em;
  padding-top: 0.8em;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
}

.package-card-form-container.rtl {
  direction: rtl;
}

.package-card-price-container {

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.8em;
  font-weight: 800;
}

.package-card-price-container.red {

  background-color: #ee3823;
}


.package-card-price-container.blue {

  background-color: #009cff;
}

.package-card-price {}

.package-card-form-times {
  display: flex;
  flex: 1;
  padding-right: 1em;
  padding-left: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.package-card-form-input-row {
  /* font-size: 0.7em; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5em;
}

.package-card-form-quantity-container {
  flex: 1;
  text-align: center;
  padding-bottom: 0.3em;
  /* font-size: 0.7em; */
}

.package-card-form-quantity input {
  text-align: center;
  border: none;
  width: 2em;
}

.package-card-form-input-row span {
  width: 25vw;
}

.package-card-form-input {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 100%;
  border: none;
  padding: none;
  outline: none;
  margin: none;
}

.package-card-form-input-container {
  border: 1.2px solid #cececd;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
}

.package-card-form-input-icon-container {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  padding: 2px;
  text-align: center;
}

.package-card-form-input-icon-container:focus {
  outline: none;
}

.package-card-arrow-container {}

.package-card-arrow {
  width: 1.2em;
}

.package-card-form-input-icon-container.start {
  background-color: #f6f6f4;
  border-inline-end-color: #cececd;
  border-inline-end-width: 1.2px;
  border-inline-end-style: solid;

}

.package-card-form-input-icon-container.end {
  background-color: #f6f6f4;
  border-inline-start-color: #cececd;
  border-inline-start-width: 1.2px;
  border-inline-start-style: solid;

}

.package-card-form-input-icon {
  font-size: 0.8em;
  color: #cececd;
  padding: 2px;
}




/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .package-card-form-times {
    flex: 2;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  .package-card-form-times span {
    width: auto;
    margin-inline-start: 0.8em;
    margin-inline-end: 0.8em;
    font-weight: bold;

  }

  .package-card-form-times input {
    width: auto;
  }

  .package-card-form-quantity {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-weight: bold;

  }

  .package-card-title {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-subtitle {
    font-size: 1.8em;
    font-weight: 800;
  }

  .package-card-info-container {
    padding: 0;

  }

  .package-card-info-titles {
    flex: 1;


    border-inline-end-color: #009dfe;
    border-inline-end-width: 2px;
    border-inline-end-style: solid;

  }

  .package-card-extra-info {
    padding-inline-start: 2em;
    font-size: 0.85em;
    flex: 2;
    font-weight: 200;
  }


  .package-card-info-container {
    flex-direction: row;
    justify-content: space-around;

  }

  .package-card-info-titles {
    display: flex;
    line-height: 1.6em;
    flex-direction: column;
    justify-content: center;
    padding-inline-start: 1em;

  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .package-card-form-times {
    flex: 2;
    align-items: center;
    flex-direction: row;
  }

  .package-card-form-times span {
    width: auto;
    margin-inline-start: 0.8em;
    margin-inline-end: 0.8em;
    font-weight: bold;

  }

  .package-card-form-times input {
    width: auto;
    flex: 1;
  }

  .package-card-form-quantity {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-weight: bold;

  }



  .package-card-info-container {
    flex-direction: row;
    justify-content: space-around;

  }

  .package-card-title {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-subtitle {
    font-size: 1.8em;
    font-weight: 800;
  }

  .package-card-info-container {
    padding: 0;

  }

  .package-card-info-titles {
    flex: 1;


    border-inline-end-color: #009dfe;
    border-inline-end-width: 2px;
    border-inline-end-style: solid;

  }

  .package-card-extra-info {
    padding-inline-start: 2em;
    font-size: 0.85em;
    flex: 2;
    font-weight: 200;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .package-card-form-times {
    flex: 2;
    align-items: center;
    flex-direction: row;
  }

  .package-card-form-times span {
    width: auto;
    margin-inline-start: 0.8em;
    margin-inline-end: 0.8em;
    font-weight: bold;

  }

  .package-card-form-times input {
    width: auto;

  }

  .package-card-form-quantity {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-weight: bold;

  }



  .package-card-info-container {
    flex-direction: row;
    justify-content: space-around;

  }

  .package-card-title {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-subtitle {
    font-size: 1.3em;
    font-weight: 800;
  }

  .package-card-info-container {
    padding: 0;

  }

  .package-card-info-titles {
    flex: 1;
    flex-direction: column;
    justify-content: center;

    border-inline-end-color: #009dfe;
    border-inline-end-width: 2px;
    border-inline-end-style: solid;

    display: flex;
    line-height: 1.6em;

    padding-inline-start: 1em;

  }


  .package-card-extra-info {
    padding-inline-start: 2em;
    font-size: 0.85em;
    flex: 2;
    font-weight: 200;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}