.card-image {
  height: 30vw;
  width: 40vw;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
}



.card-image.rtl {
  direction: rtl;


}

.title {
  font-weight: 100;
  color: #ffffff;
  font-size: 1.15em;
  display: block;
}

.subtitle {
  font-weight: 900;
  color: #ffffff;
  font-size: 1.15em;
  display: block;
}



/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .card-image {
    width: 18vw;
    height: 10vw;
    padding: 16px;
  }

  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 2em;
  }

  .promo-card-container {
    display: flex;
    flex-direction: row;
  }

  .promo-card-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left;
    padding: 20px;
  }

  .promo-card-extra.rtl {
    text-align: right
  }


}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {

  .card-image {
    width: 18vw;
    height: 10vw;
    padding: 16px;
  }

  .promo-card-extra.rtl {
    text-align: right
  }


  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.4em;
  }

  .promo-card-container {
    display: flex;
    flex-direction: row;
  }

  .promo-card-extra {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 20px;
    max-width: 18vw;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .card-image {
    width: 25vw;
    height: 12vw;
    padding: 12px;
  }

  .title {
    font-weight: 100;
    font-size: 1.4em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.4em;
  }



}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .card-image {
    width: 32vw;
    height: 15vw;
    padding: 16px;
  }

  .title {
    font-weight: 100;
    font-size: 1.5em;
  }

  .subtitle {
    font-weight: 900;
    font-size: 1.5em;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {}