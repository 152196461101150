.omni-progress-container {
  direction: ltr;
  border: 1px solid #ed3823;
  padding: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.omni-progress-fill {
  animation-name: filling;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-radius: 10px;
  height: 12px;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: rgba(239, 244, 244, 1);
  background: -moz-linear-gradient(left, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(239, 244, 244, 1)), color-stop(100%, rgba(248, 154, 147, 1))) !important;
  background: -webkit-linear-gradient(left, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  background: -o-linear-gradient(left, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  background: -ms-linear-gradient(left, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  background: linear-gradient(to right, rgba(239, 244, 244, 1) 0%, rgba(248, 154, 147, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eff4f4', endColorstr='#f89a93', GradientType=1) !important;
}

@keyframes filling {
  from {
    width: 5%;
  }

  to {
    width: 99%;
  }
}

.omni-progress-knob {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #ed3823;
}