.tooltip-container {
  height: 5em;
  width: 8em;
  display: flex;
  padding: 1em;
  position: relative;
}

.tooltip-container.rtl {
  direction: rtl
}



.tooltip-content {
  font-size: 0.6em;
}

.tooltip-icon {
  width: 1em;
  height: 1em;
  position: absolute;
  top: 0.3em;
  right: -0.2em;
}

.tooltip-title {
  display: block;
  font-weight: bold;
  font-size: 12px;
}
